import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import './index.scss'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
    }
  }
}

const Layout: React.FC = ({ children }) => {
  const render = (data: StaticQueryProps) => (
    <div>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          { name: 'charset', content: 'UTF-8' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          { name: 'google-site-verification', content: 'cS3oH8e9ShihcRKOh0dMmogqdYfDDUVpuzCNoBmlTIc' },
          { property: 'og:title', content: 'Kanji Lernen. Auf Deutsch.' },
          { property: 'og:description', content: 'Lerne Kanji mit Beispielwörtern lesen und schreiben.'  },
          { property: 'og:image', content: 'https://cotsu.de/cotsu-kanji-trainer-deutsch.jpg' },
          { property: 'og:image:width', content: '1200' },
          { property: 'og:image:height', content: '630' },
          { property: 'og:url', content: 'https://cotsu.de/' },
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:site', content: '@jakobsjapanisch' },
          { name: 'twitter:creator', content: '@jakobsjapanisch' },
          { name: 'twitter:title', content: 'Kanji Lernen. Auf Deutsch.' },
          { name: 'twitter:image:src', content: 'https://cotsu.de/cotsu-kanji-trainer-deutsch.jpg' },
          { name: 'twitter:description', content: 'Lerne Kanji mit Beispielwörtern lesen und schreiben.' },       
          { name: 'apple-mobile-web-app-title', content: 'コツコツ' },
          { name: 'application-name', content: 'コツコツ' },
          { name: 'msapplication-TileColor', content: '#eb4654' },
          { name: 'theme-color', content: '#eb4654' }
        ]}
        link={[
          { rel: "apple-touch-icon", sizes: "180x180", href: "/favicon/apple-touch-icon.png" },
          { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon/favicon-32x32.png" },
          { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon/favicon-16x16.png" },
          { rel: "manifest", href: "/favicon/site.webmanifest" },
          { rel: "mask-icon", href: "/favicon/safari-pinned-tab.svg", color: "#5bbad5" }
        ]}
        />
      <div style={{ margin: '0 auto', paddingTop: 0 }}>
        {children}
      </div>
    </div>
    )

  return (<StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={render}
  />)
}

export default Layout
